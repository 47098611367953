@import '../theme/mixins';

$brand: 'jamies';
$brandAssets: '/assets/#{$brand}';
@import '../theme/mixins';

@font-face {
	font-family: AppFont;
	src: url('../assets/fonts/Cabin-Regular.ttf');
}

@font-face {
	font-family: AppFontBold;
	src: url('../assets/fonts/BarlowCondensed-SemiBold.ttf');
}


$app-primary: mat-palette($mat-indigo);
$app-accent: mat-palette($mat-pink, A200, A100, A400);

$app-warn: mat-palette($mat-red);

$app-theme: mat-light-theme($app-primary, $app-accent, $app-warn);

@include angular-material-theme($app-theme);

:root {

	--ion-color-primary: #17313A;
	--ion-color-primary-rgb: 23,49,58;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #142b33;
	--ion-color-primary-tint: #2e464e;

	--ion-color-secondary: #FCEA00;
	--ion-color-secondary-rgb: 252,234,0;
	--ion-color-secondary-contrast: #000000;
	--ion-color-secondary-contrast-rgb: 0,0,0;
	--ion-color-secondary-shade: #dece00;
	--ion-color-secondary-tint: #fcec1a;

	/** tertiary **/
	--ion-color-tertiary: #7044ff;
	--ion-color-tertiary-rgb: 112, 68, 255;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255, 255, 255;
	--ion-color-tertiary-shade: #633ce0;
	--ion-color-tertiary-tint: #7e57ff;

	/** payment-success **/
	--ion-color-success: #10dc60;
	--ion-color-success-rgb: 16, 220, 96;
	--ion-color-success-contrast: #ffffff;
	--ion-color-success-contrast-rgb: 255, 255, 255;
	--ion-color-success-shade: #0ec254;
	--ion-color-success-tint: #28e070;

	/** warning **/
	--ion-color-warning: #ffce00;
	--ion-color-warning-rgb: 255, 206, 0;
	--ion-color-warning-contrast: #ffffff;
	--ion-color-warning-contrast-rgb: 255, 255, 255;
	--ion-color-warning-shade: #e0b500;
	--ion-color-warning-tint: #ffd31a;

	/** danger **/
	--ion-color-danger: #f04141;
	--ion-color-danger-rgb: 245, 61, 61;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255, 255, 255;
	--ion-color-danger-shade: #d33939;
	--ion-color-danger-tint: #f25454;

	/** dark **/

	--ion-color-dark: #474030;
	--ion-color-dark-rgb: 84, 80, 76;
	--ion-color-dark-contrast: #ffffff;
	--ion-color-dark-contrast-rgb: 255, 255, 255;
	--ion-color-dark-shade: #4a4643;
	--ion-color-dark-tint: #65625e;

	/** medium **/
	--ion-color-medium: #989aa2;
	--ion-color-medium-rgb: 152, 154, 162;
	--ion-color-medium-contrast: #ffffff;
	--ion-color-medium-contrast-rgb: 255, 255, 255;
	--ion-color-medium-shade: #86888f;
	--ion-color-medium-tint: #a2a4ab;

	/** light **/
	--ion-color-light: #f4f5f8;
	--ion-color-light-rgb: 244, 244, 244;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0, 0, 0;
	--ion-color-light-shade: #d7d8da;
	--ion-color-light-tint: #f5f6f9;

	--ion-color-second: #e41375;
	--ion-color-second-rgb: 242, 183, 0;
	--ion-color-second-contrast: #ffffff;
	--ion-color-second-contrast-rgb: 255, 255, 255;
	--ion-color-second-shade: #f2b700;
	--ion-color-second-tint: #f2b700;

	--ion-color-gray-light: #f5f5f5;
	--ion-color-gray-dark: #d4d4d4;
	--ion-color-gray-darkest: #1f1b1c;

	--ion-color-black: #474030;
	--ion-color-label: #66605a;

	--ion-default-font: AppFont, serif !important;
	--ion-text-color: var(--ion-color-black);
	--quantity-selector-border-width: 1px;
	--quantity-selector-border-color: var(--ion-color-primary);
	--quantity-selector-height: 40px;
	--quantity-selector-background: white;
	--quantity-selector-text-color: var(--ion-color-primary);
	--quantity-rectangle-background: #fff;
	--quantity-font-size: 18px;

	--background-default: #FFFFFF;
  --color-black: #243239;
  --radius-default: 0.6px;
}

.ion-color-main-color {
	--ion-color-base: var(--ion-color-main);
	--ion-color-base-rgb: var(--ion-color-main-rgb);
	--ion-color-contrast: var(--ion-color-main-contrast);
	--ion-color-contrast-rgb: var(--ion-color-main-contrast-rgb);
	--ion-color-shade: var(--ion-color-main-shade);
	--ion-color-tint: var(--ion-color-main-tint);
}

.ion-color-second-color {
	--ion-color-base: var(--ion-color-second);
	--ion-color-base-rgb: var(--ion-color-second-rgb);
	--ion-color-contrast: var(--ion-color-second-contrast);
	--ion-color-contrast-rgb: var(--ion-color-second-contrast-rgb);
	--ion-color-shade: var(--ion-color-second-shade);
	--ion-color-tint: var(--ion-color-second-tint);
}

.ion-color-black {
	--ion-color-base: var(--ion-color-black);
	--ion-color-base-rgb: var(--ion-color-black);
	--ion-color-contrast: var(--ion-color-main-contrast);
	--ion-color-contrast-rgb: var(--ion-color-main-contrast-rgb);
	--ion-color-shade: var(--ion-color-main-shade);
	--ion-color-tint: var(--ion-color-main-tint);
}

html,
body {
	height: 100%;
	font-family: AppFont, 'Helvetica Neue', sans-serif;
	color: var(--color-black);
}
h1, h2, h3, h4 app-payment-success{
  font-family: AppFontBold, Helvetica, sans-serif;
  color: var(--color-black);
}

p, span {
  margin: 0;
  color: var(--color-black);
  font-family: AppFont;
}

body {
	margin: 0;
}

ion-label {
	color: var(--ion-color-label);
}


app-home {
	background: var(--ion-color-primary) no-repeat center center / 100% 100% !important;
	background-size: cover !important;

	@include mobile {
		background: var(--ion-color-primary) no-repeat center center / 100% 100% !important;
	}

	ion-header {
		background: transparent;
	}

	ion-content {
		background: transparent !important;
		--background: transparent !important;
	}
}
app-menu {
	background: var(--background-default) no-repeat center top / 100% !important;

	ion-header {
		background: transparent;
	}

	ion-content {
		background: transparent !important;
		--background: transparent !important;
	}
}

app-menu-modal {
	background: var(--background-default)  no-repeat right bottom /
		100% !important;

	ion-header {
		background: transparent;
	}

	ion-content {
		background: transparent !important;
		--background: transparent !important;
	}
}

app-payment-success {
	background: var(--ion-color-primary) !important;
	@include mobile {
		background: var(--ion-color-primary) !important;
	}
	background-position: center top;
	ion-content {
		background: transparent !important;
		--background: transparent !important;
	}
}
